/* eslint-disable @typescript-eslint/no-explicit-any */
type PiiType = 'email' | 'firstName' | 'lastName' | 'phoneNumber' | 'token';

const piiKeys: Record<PiiType, RegExp> = {
  email: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  firstName: /<firstName_regex>/,
  lastName: /<lastName_regex>/,
  phoneNumber:
    /^(?:\+?1[-. ]?)?\(?([2-9][0-8][0-9])\)?[-. ]?([2-9][0-9]{2})[-. ]?([0-9]{4})(?:\D|$)/,
  token: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
};

const obfuscate = (value: string): string => {
  if (value.length <= 2) return '*'.repeat(value.length);
  return value[0] + '*'.repeat(value.length - 2) + value[value.length - 1];
};

const queryStringToObject = (query: string): Record<string, any> => {
  const params = new URLSearchParams(query);
  const obj: Record<string, any> = {};
  params.forEach((value, key) => {
    obj[key] = value;
  });
  return obj;
};

const maskPiiKeys = (params: Record<string, any>) => {
  const afUrlMask: string[] = [];

  Object.keys(params).forEach(key => {
    if (key in piiKeys) {
      afUrlMask.push(key);
    }
  });

  if (afUrlMask.length > 0) {
    params.af_url_mask = `${afUrlMask.join(';')};`;
  }

  return params;
};

const objectToQueryString = (obj: Record<string, any>): string => {
  const params = new URLSearchParams();
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value !== 'object') {
      params.append(key, value);
    }
  });
  return params.toString();
};

const removePiiFromQueryString = (
  query: string,
  piiAction: 'redact' | 'obfuscate' | 'remove',
): string => {
  const obj = queryStringToObject(query);
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const newObj = removePii(obj, piiAction);
  return objectToQueryString(newObj);
};

const removePii = (
  obj: Record<string, any>,
  piiAction: 'redact' | 'obfuscate' | 'remove',
  keys: Record<PiiType, RegExp> = piiKeys,
): Record<string, any> => {
  const newObj: Record<string, any> = {};
  Object.entries(obj ?? {}).forEach(([key, value]) => {
    const isPiiKey = Object.keys(keys).includes(key);

    if (typeof value === 'object' && !Array.isArray(value)) {
      newObj[key] = removePii(value, piiAction, keys);
    } else if (Array.isArray(value)) {
      newObj[key] = value.map(item =>
        typeof item === 'object' ? removePii(item, piiAction, keys) : item,
      );
    } else if (typeof value === 'string' && value.startsWith('?')) {
      newObj[key] = removePiiFromQueryString(value, piiAction);
    } else {
      const piiKey = Object.entries(keys).find(([, regex]) => regex.test(value));

      if (piiKey || isPiiKey) {
        if (piiAction === 'redact') {
          newObj[key] = 'REDACTED';
        } else if (piiAction === 'obfuscate') {
          newObj[key] = obfuscate(value);
        } // else 'remove' - do not add the key to the new object
      } else {
        newObj[key] = value;
      }
    }
  });

  return newObj;
};

const applyPiiAction =
  (piiAction: 'redact' | 'obfuscate' | 'remove') => (obj: Record<string, any>) =>
    removePii(obj, piiAction);

const obfuscatePii = applyPiiAction('obfuscate');

const applySafeQueryString = (obj: Record<string, any>) => objectToQueryString(maskPiiKeys(obj));

export {
  removePii,
  applyPiiAction,
  obfuscate,
  queryStringToObject,
  objectToQueryString,
  removePiiFromQueryString,
  piiKeys,
  obfuscatePii,
  maskPiiKeys,
  applySafeQueryString,
};
