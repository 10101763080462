export const EVENTS = {
  /**
   * Navigation
   */
  SECTION_ACCORDION_TOGGLED: 'navigation menu section accordion toggled',
  MOBILE_NAV_TOGGLED: 'navigation menu mobile nav toggled',

  /**
   * About
   */
  ABOUT_JOB_OPENINGS_CLICKED: 'job openings clicked',

  /**
   * Careers
   */
  CAREERS_JOB_OPENINGS_CLICKED: 'job openings clicked',
  CAREERS_CATEGORY_FILTERED: 'job openings category filtered',

  /**
   * Links
   */
  LINK_CLICKED: 'link clicked',
  LINK_BUTTON_CLICKED: 'link button clicked',
  APP_STORE_LINK_CLICKED: 'link app store clicked',

  /**
   * Forms
   */
  FORM_FIELD_AUTOFILL: 'form fields autofill',
  FORM_FIELD_UPDATED: 'form field updated',
  FORM_VALIDATION_FAILED: 'form validation failed',
  FORM_SUBMITTED: 'form submitted',

  /**
   * Modal Dialog
   */
  MODAL_DIALOG_VIEWED: 'modal dialog viewed',

  /**
   * Registration
   */
  FORM_FIELD_VALIDATION_FAILED: 'registration form field validation failed',
  SMS_CHECKBOX_TOGGLED: 'sms notification checkbox toggled',
  PHONE_NUMBER_REGISTER_LOADED: 'registration phone screen viewed',
  PHONE_NUMBER_VERIFY_LOADED: 'phone number verify screen loads',
  WELCOME_BACK_LOADED: 'welcome back screen loads',
  SUCCESSFUL_REGISTRATION_LOADED: 'registration download screen viewed',
  PHONE_NUMBER_RESEND_VERIFICATION_CODE: 'phone number resend verification code',
  PHONE_NUMBER_RESEND_VERIFICATION_CODE_SUCCESS: 'phone number resend verification code success',
  PHONE_NUMBER_RESEND_VERIFICATION_CODE_FAILED: 'phone number resend verification code failed',
  PHONE_NUMBER_VERIFICATION_REQUESTED: 'phone number verification requested',
  PHONE_NUMBER_VERIFICATION_SUCCESS: 'phone number verified',
  PHONE_NUMBER_VERIFICATION_FAILED: 'phone number verification failed',
  PHONE_NUMBER_VERIFICATION_HELP_MODAL_OPENED: 'phone number help verification help modal opened',
  PHONE_NUMBER_VERIFICATION_HELP_MODAL_CLOSED: 'phone number help verification help modal closed',
  EMAIL_AND_PASSWORD_CREATION_SUCCESS: 'email and password creation success',
  EMAIL_AND_PASSWORD_CREATION_FAIL: 'email and password creation failed',
  NOTIFICATIONS_ENABLED: 'notifications enabled',
  JOIN_DAVE_ATTEMPT: 'user account register attempt',
  JOIN_DAVE_SUCCESS: 'user account register success',
  JOIN_DAVE_FAILED: 'user account register failure',
  PAPI_REFERRAL_LINK_FETCH_FAIL: 'papi referral link fetch fail',

  /**
   * Referrals
   */
  PROMO_GET_LINK_FAILED: 'promotions get link failure',
  RECIPIENT_LANDING_PAGE_VIEWED: 'recipient landing page viewed',

  /**
   * Accordion
   */
  ACCORDION_TOGGLED: 'accordion toggled',

  /**
   * CCPA
   * */
  CCPA_FIELD_VALIDATION_FAILED: 'ccpa form field validation failed',
  CCPA_FORM_SUBMIT_FAILED: 'ccpa form submit failed',
  CCPA_FORM_SUCCESS_VIEWED: 'ccpa form success viewed',

  /**
   * Set Password
   */
  SET_PASSWORD_SUCCESS_PAGE_VIEWED: 'set password success page viewed',
};
