import * as Amplitude from '../../amplitude';
import * as AppsFlyer from '../../appsflyer';
import * as Braze from '../../braze';
import { Config, isDevEnv } from '../../config';
import * as Firebase from '../../firebase';
import { isServer } from '../../helpers';
import * as Datadog from '../datadog-logs';

import { generateDataWithWebContext } from './generate-web-context';
import { obfuscatePii } from './handle-pii';

/**
 * Track an event with our analytics platforms. The implementations for some destinations'
 * trackEvent may have whitelists for what events to send. Amplitude will get sent every event that
 * is passed to this function.
 *
 * @param event This is the event name to be sent to the analytics platforms
 * @param all       Properties that will be sent to every analytics platform
 * @param amplitude Properties that will only be sent to amplitude
 * @param appsFlyer Properties that will only be sent to appsFlyer
 * @param braze     Properties that will only be sent to braze
 * @param firebase  Properties that will only be sent to firebase
 * @param rest      Any properties without a destination will be sent to
 *                  every analytics provider.
 */

export const trackEvent = (
  event: string,
  {
    all = {},
    amplitude = {},
    appsFlyer = {},
    braze = {},
    datadog = {},
    firebase = {},
    ...rest
  } = {},
): void => {
  if (!Config.ENABLE_ANALYTICS || isServer) return;

  const dataWithWebContext = generateDataWithWebContext({ ...all, ...rest });
  const dataWithWebContextAndPiiRemoved = obfuscatePii(dataWithWebContext);

  try {
    if (isDevEnv()) {
      // eslint-disable-next-line no-console
      console.debug(`Track Event -- ${event}`, dataWithWebContextAndPiiRemoved);
    }
    Amplitude.trackEvent(event, {
      ...dataWithWebContextAndPiiRemoved,
      ...obfuscatePii(amplitude),
    });
    AppsFlyer.trackEvent(event, {
      ...dataWithWebContextAndPiiRemoved,
      ...obfuscatePii(appsFlyer),
    });
    Braze.trackEvent(event, {
      ...dataWithWebContextAndPiiRemoved,
      ...obfuscatePii(braze),
    });
    Datadog.trackEvent(event, {
      ...dataWithWebContextAndPiiRemoved,
      ...obfuscatePii(datadog),
    });
    Firebase.trackEvent(event, {
      ...dataWithWebContextAndPiiRemoved,
      ...obfuscatePii(firebase),
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Error tracking event -- ${event}`, e);
  }
};
