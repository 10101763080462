import * as AppsFlyer from '../../appsflyer';
import { Config, isDevEnv } from '../../config';
import * as Firebase from '../../firebase';

import { addExperimentMetaData } from './generate-experiment-context';

import { generateDataWithWebContext } from './generate-web-context';
import { obfuscatePii } from './handle-pii';

/**
 * Track a page view with our analytics platforms. The implementations for some destinations'
 * trackPageView may have whitelists for what events to send. Amplitude will get sent every event that
 * is passed to this function.
 *
 * @param pageName  This is the page name to be sent to the analytics platforms
 * @param all       Properties that will be sent to every analytics platform
 * @param amplitude Properties that will only be sent to amplitude
 * @param appsFlyer Properties that will only be sent to appsFlyer
 * @param braze     Properties that will only be sent to braze
 * @param firebase  Properties that will only be sent to firebase
 * @param rest      Any properties without a destination will be sent to
 *                  every analytics provider.
 */

type ComposeFn<T, U> = (data: T) => U;

const compose =
  <T, U>(...fns: Array<ComposeFn<U, U>>): ComposeFn<T, U> =>
  (data: T) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fns.reduceRight((result, fn) => fn(result), data as any) as U;

export const trackPageView = (
  pageName: string,
  // TODO: Remove eslint override as we add each of these destinations
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { all = {}, amplitude = {}, appsFlyer = {}, braze = {}, firebase = {}, ...rest } = {},
): void => {
  if (!Config.ENABLE_ANALYTICS) return;

  const data = compose(
    obfuscatePii,
    addExperimentMetaData,
    generateDataWithWebContext,
  )({ ...all, ...rest });

  try {
    if (isDevEnv()) {
      // eslint-disable-next-line no-console
      console.debug(`Track Pageview -- ${pageName}`, data);
    }
    AppsFlyer.trackPageView(pageName);
    Firebase.trackPageView(pageName);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Error tracking pageview -- ${pageName}`, e);
  }
};
