import { datadogRum } from '@datadog/browser-rum';

import { Config } from '../config';

const { applicationId, clientToken, service, site } = Config.DATADOG_CONFIG;

export const datadogRumInit = () => {
  if (applicationId && clientToken && service) {
    datadogRum.init({
      applicationId,
      clientToken,
      env: Config.ENVIRONMENT,
      defaultPrivacyLevel: 'mask-user-input',
      service,
      sessionSampleRate: 0,
      sessionReplaySampleRate: 0,
      site,
      trackFrustrations: true,
      trackLongTasks: true,
      trackResources: true,
      trackUserInteractions: true,
    });

    // Sample rate configured in DataDog
    datadogRum.startSessionReplayRecording();
  }
};
