import {
  initializeAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
  CustomParams,
  CustomEventName,
  EventNameString,
  Analytics,
} from 'firebase/analytics';
import { getApps, initializeApp } from 'firebase/app';

import { Config } from './config';

type TransformEventReturn = {
  event: string;
  [key: string]: unknown;
};

const firebaseApps = getApps();
let firebaseAnalytics: Analytics;

// Only init analytics once to prevent errors with Fast Refresh
// https://www.gatsbyjs.com/docs/reference/local-development/fast-refresh/
if (firebaseApps?.length === 0 && typeof window !== 'undefined') {
  const firebaseApp = initializeApp(Config.FIREBASE_CONFIG);
  // turn off auto logging of page_view
  firebaseAnalytics = initializeAnalytics(firebaseApp, {
    config: {
      send_page_view: false,
    },
  });
}

/**
 * Replaces all non-alpha non-numeric characters in a string with an underscore
 *
 * @param str  Event name
 */
export function cleanString(str: CustomEventName<string>): CustomEventName<string> {
  // replace non-alpha non-numeric characters with an underscore
  let cleaned = str.replace(/[^a-zA-Z0-9]/g, '_');
  // remove leading, trailing, and double underscores
  cleaned = cleaned.replace(/^_+|_+$|__/g, '');
  return cleaned;
}

/**
 * Firebase mobile app event names & params have constraints
 * https://firebase.google.com/docs/reference/android/com/google/firebase/analytics/FirebaseAnalytics.Param
 * For consistency with mobile app, converting all event names to snake_case
 *
 * @param originalEvent  Event name
 * @param originalParams Properties that will be sent to Firebase Analytics
 */
export function transformEvent(
  originalEvent: CustomEventName<string>,
  originalParams: CustomParams = {},
): TransformEventReturn {
  const event = cleanString(originalEvent);
  const params = Object.keys(originalParams).reduce((acc: CustomParams, param) => {
    if (originalParams[param] != null) {
      const paramName = cleanString(param);
      let paramValue = originalParams[param];
      if (typeof paramValue === 'object') {
        paramValue = JSON.stringify(paramValue);
      }
      if (typeof paramValue === 'string') {
        paramValue = cleanString(paramValue);
      }
      acc[paramName] = paramValue;
    }
    return acc;
  }, {});
  return { event, params };
}

export const trackEvent = (
  originalEvent: CustomEventName<string>,
  originalParams: CustomParams = {},
): void => {
  const { event, params } = transformEvent(originalEvent, originalParams);
  if (event.length) {
    logEvent(firebaseAnalytics, event, params as { [key: string]: string });
  }
};

export const trackPageView = (screen: CustomEventName<string>): void => {
  logEvent(firebaseAnalytics, 'page_view' as CustomEventName<EventNameString>, {
    firebase_screen: screen,
  });
};

export const setUser = (userId: string): void => {
  setUserId(firebaseAnalytics, userId);
};

export const setUserProperty = (property: string, value: string | number): void => {
  setUserProperties(firebaseAnalytics, { [property]: value });
};

export const setLanguage = (language: string): void => {
  setUserProperty('language', language);
};
