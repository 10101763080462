import { Variant } from '@amplitude/experiment-js-client';

export const Experiments = {} as const;

export type ExperimentsType = typeof Experiments;
export type ExperimentKeys = keyof ExperimentsType;

export type DaveVariant<K extends ExperimentKeys> = {
  key?: ExperimentsType[K][number];
  value?: ExperimentsType[K][number];
} & Omit<Variant, 'value' | 'key'>;

export type DaveVariants = {
  [K in ExperimentKeys]: DaveVariant<K>;
};
