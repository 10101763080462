import { Variants } from '@amplitude/experiment-js-client';

import { getExperimentVariantsSync } from '../../amplitude';
import { Experiments } from '../../experiments';

type DataWithExpContextReturn = {
  [key: string]: unknown;
  variants: Partial<Variants>;
};

/**
 * Filters only watched variants based on `Experiments` object
 */
export const filterWatchedVariants = (
  allowList: Array<string>,
  variants: Variants,
): Partial<Variants> =>
  allowList.reduce(
    (acc: Partial<Variants>, key) => ({
      ...acc,
      [key]: variants[key],
    }),
    {} as Partial<Variants>,
  );

/**
 * Appends web app experiment params to every analytics event
 *
 * @param params Properties that will be sent to every analytics platform
 */
export const addExperimentMetaData = (params = {}): DataWithExpContextReturn => {
  const variants = getExperimentVariantsSync();
  const dataWithExperimentContext = {
    ...params,
    variants: filterWatchedVariants(Object.keys(Experiments), variants),
  };

  return dataWithExperimentContext;
};
