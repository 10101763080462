import * as ReactDOM from 'react-dom/client';

import './src/assets/styles/fonts.css';
import './src/assets/styles/global.css';

import { experimentsInit } from './src/lib/amplitude';
import { datadogRumInit, trackPageView } from './src/lib/analytics';
import { Config } from './src/lib/config';

/**
 * Track an event with our analytics platforms every time the client route changes
 * https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#onRouteUpdate
 *
 */
export const onRouteUpdate = props => {
  const { hash, search, pathname } = props?.location || '';
  // wrap inside a timeout to make sure react-helmet is done with its changes (https://github.com/gatsbyjs/gatsby/issues/9139)
  // reactHelmet is using requestAnimationFrame: https://github.com/nfl/react-helmet/blob/5.2.0/src/HelmetUtils.js#L296-L299
  setTimeout(() => {
    // Removes first '/' from path name and set to "home" if empty
    const cleanPathname = pathname.substring(1) || 'home';

    // Gets page name set in Contentful
    const contentfulPageName = document.querySelector('meta[name="page-name"]')?.content;
    const isPageNameNull = !contentfulPageName || contentfulPageName === 'null';

    const pageName = isPageNameNull ? cleanPathname : contentfulPageName;
    trackPageView(pageName, { hash, search });
  }, 1);
};

export const onClientEntry = () => {
  // Analytics disabled by default in Dev env
  if (Config.ENABLE_ANALYTICS) {
    datadogRumInit();
  }

  experimentsInit();
};

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};
