import { datadogLogs } from '@datadog/browser-logs';

import { Config } from '../config';

const { clientToken, service, site } = Config.DATADOG_CONFIG;

datadogLogs.init({
  clientToken,
  service,
  site,
});

export const stripPii = (params: Record<string, never> = {}) => {
  delete params?.email;
  delete params?.firstName;
  delete params?.lastName;
  delete params?.password;
  delete params?.phoneNumber;
  return params;
};

export const trackEvent = (event: string, params = {}): void => {
  datadogLogs.logger.info(event, stripPii(params));
};
