import * as amplitude from '@amplitude/analytics-browser';
import { Experiment, ExperimentUser } from '@amplitude/experiment-js-client';

import { Config } from './config';
import { DaveVariant, DaveVariants, ExperimentKeys } from './experiments';

const isBrowser = typeof window !== 'undefined';

if (isBrowser) {
  amplitude.init(Config.AMPLITUDE_ANALYTICS_TOKEN, {
    defaultTracking: {
      attribution: true,
      pageViews: true,
      sessions: true,
      formInteractions: true,
    },
  });
}

const experiment = Experiment.initializeWithAmplitudeAnalytics(Config.AMPLITUDE_EXPERIMENT_TOKEN, {
  automaticExposureTracking: false,
  automaticFetchOnAmplitudeIdentityChange: true,
});

export const experimentsInit = async (user?: ExperimentUser | undefined) => {
  await experiment.fetch(user);
};

export const getExperimentVariants = async (user?: ExperimentUser | undefined) => {
  await experiment.fetch(user);
  return experiment.all();
};

/**
 * Synchronous fetch all experiment; requires experiment to be initialized already
 * Beware if user context changes (possible side-effect)
 */
export const getExperimentVariantsSync = (): DaveVariants => {
  return experiment.all() as DaveVariants;
};

export const getSingleExperimentVariant = <K extends ExperimentKeys>(
  flagKey: K,
): DaveVariant<K> => {
  return experiment.variant(flagKey) as DaveVariant<K>;
};

export const trackEvent = (event: string, params = {}): void => {
  amplitude.track(event, params);
};

export const trackExposure = (flagKey: string): void => {
  experiment.exposure(flagKey);
};

export const trackPageView = (event: string, params = {}): void => {
  trackEvent(event, params);
};

export const getDeviceId = (): string | undefined => {
  return amplitude.getDeviceId();
};

export const setUserId = (userId: string | number): void => {
  amplitude.setUserId(String(userId));
};

export const addToUserProperty = (property: string, amount: number): void => {
  const identifyObj = new amplitude.Identify();
  identifyObj.add(property, amount);
  amplitude.identify(identifyObj);
};

export const setUserProperty = (
  property: string,
  value: amplitude.Types.ValidPropertyType,
): void => {
  const identifyObj = new amplitude.Identify();
  identifyObj.set(property, value);
  amplitude.identify(identifyObj);
};

export const setLanguage = (language: string): void => {
  setUserProperty('language', language);
};

export const flushEvents = (): void => {
  amplitude.flush();
};

export const resetUserAndDeviceId = (): void => {
  return amplitude.reset();
};
