/* eslint-disable @typescript-eslint/ban-ts-comment */
import { EVENTS } from './analytics/constants/analytics-events';

const APPSFLYER_EVENTS = [
  EVENTS.SECTION_ACCORDION_TOGGLED,
  EVENTS.MOBILE_NAV_TOGGLED,
  EVENTS.ABOUT_JOB_OPENINGS_CLICKED,
  EVENTS.CAREERS_JOB_OPENINGS_CLICKED,
  EVENTS.CAREERS_CATEGORY_FILTERED,
  EVENTS.LINK_CLICKED,
  EVENTS.LINK_BUTTON_CLICKED,
  EVENTS.APP_STORE_LINK_CLICKED,
  EVENTS.PHONE_NUMBER_VERIFICATION_SUCCESS,
];

// AF is an AppsFlyer function added in public/appsflyer-sdk.js
// Typescript error is from AF not existing in Window type
// https://support.appsflyer.com/hc/en-us/articles/360001610038-PBA-Web-SDK-integration-guide#recording-events

export const setUserId = (userId: string | number): void => {
  // @ts-ignore
  window.AF('pba', 'event', { eventType: 'IDENTIFY', customUserId: `${userId}` });
};

export const trackEvent = (event: string, params = {}): void => {
  if (APPSFLYER_EVENTS.indexOf(event) >= 0) {
    // @ts-ignore
    window.AF('pba', 'event', { eventType: 'EVENT', eventName: event, ...params });
  }
};

export const trackPageView = (pageName: string): void => {
  // @ts-ignore
  window.AF('pba', 'event', {
    eventType: 'EVENT',
    eventName: 'page view',
    eventValue: { pageName },
  });
};
