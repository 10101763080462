type DataWithWebContextReturn = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  url: string;
  domain: string;
};

/**
 * Appends web app params to every analytics event
 *
 * @param params    Properties that will be sent to every analytics platform
 */
export const generateDataWithWebContext = (params = {}): DataWithWebContextReturn => {
  const { pathname } = window.location;
  const dataWithWebContext = {
    ...params,
    url: pathname,
    domain: 'web',
  };
  return dataWithWebContext;
};
