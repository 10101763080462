import { Types } from '@amplitude/analytics-browser';

import * as Amplitude from '../../amplitude';
import * as AppsFlyer from '../../appsflyer';

export const addToUserProperty = (property: string, amount: number): void => {
  Amplitude.addToUserProperty(property, amount);
};

export const setUserProperties = (props: Record<string, Types.ValidPropertyType>): void => {
  Object.keys(props).forEach(key => {
    Amplitude.setUserProperty(key, props[key]);
  });
};

export const setUserId = (userId: string | number): void => {
  Amplitude.setUserId(userId);
  AppsFlyer.setUserId(userId);
};
