type ConfigProps = {
  ENVIRONMENT: string;
  AMPLITUDE_ANALYTICS_TOKEN: string;
  AMPLITUDE_EXPERIMENT_TOKEN: string;
  DATADOG_CONFIG: {
    applicationId: string;
    clientToken: string;
    service: string;
    site: string;
  };
  ENABLE_ANALYTICS: boolean;
  FIREBASE_CONFIG: {
    apiKey: string;
    projectId: string;
    messagingSenderId: string;
    appId: string;
  };
  API_URL: string;
  APPSFLYER_INVITE_ONE_LINK_ID: string;
  BRAZE_KEY: string;
  BRAZE_ENDPOINT: string;
  PROMOTIONS_API_URL: string;
  MFA_SHORT_CODE: string;
};

export const ENVIRONMENT =
  process.env.GATSBY_DAVE_COM_ENVIRONMENT ?? process.env.NODE_ENV ?? 'production';

export const ENVIRONMENTS = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
  STAGING: 'staging',
};

export const Config: ConfigProps = {
  ENVIRONMENT,
  AMPLITUDE_ANALYTICS_TOKEN: '',
  AMPLITUDE_EXPERIMENT_TOKEN: '',
  DATADOG_CONFIG: {} as ConfigProps['DATADOG_CONFIG'],
  ENABLE_ANALYTICS: true,
  FIREBASE_CONFIG: {} as ConfigProps['FIREBASE_CONFIG'],
  API_URL: '',
  APPSFLYER_INVITE_ONE_LINK_ID: '',
  BRAZE_KEY: '',
  BRAZE_ENDPOINT: '',
  PROMOTIONS_API_URL: '',
  MFA_SHORT_CODE: '',
};

const defaultConfig = {
  AMPLITUDE_ANALYTICS_TOKEN: 'd1b20975f44f0cf6e780a3bcf94230be', // Publicly exposed in network requests
  AMPLITUDE_EXPERIMENT_TOKEN: 'client-agMeseYoLlidxy3kFxpjYP1TfgYrcxC1', // Publicly exposed in network requests
  DATADOG_CONFIG: {
    // Publicly exposed in network requests
    applicationId: '67df585a-e861-4b89-a81b-7c5d7a95a526',
    clientToken: 'pub14ff2797f6091080bc3c06b8837f91cb',
    service: 'web-dev',
    site: 'datadoghq.com',
  },
  ENABLE_ANALYTICS: true,
  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyBh8JzHXc421KUWLkxSrKkzkmrvarM_K2M', // Publicly exposed in browser bundle
    projectId: 'firebase-staging-cd75',
    messagingSenderId: '3305480700', // Cloud Messaging
    appId: '1:3305480700:web:ff13cd36ca681db176d279',
  },
  API_URL: 'https://staging.trydave.com',
  APPSFLYER_INVITE_ONE_LINK_ID: '2wkx',
  BRAZE_KEY: 'a1edce9a-acf9-4a59-8eaa-8b500327ba3e',
  BRAZE_ENDPOINT: 'sdk.iad-03.braze.com',
  PROMOTIONS_API_URL: 'https://promotions-staging.trydave.com',
  MFA_SHORT_CODE: '24293',
};

const prodConfig = {
  ENVIRONMENT: ENVIRONMENTS.PRODUCTION,
  AMPLITUDE_ANALYTICS_TOKEN: '7b079ef318548578662c91cd7509f36f', // Publicly exposed in network requests
  AMPLITUDE_EXPERIMENT_TOKEN: 'client-m3ZOTaK8lv5D3fSFCTWm85wTfQ6CJkE2', // Publicly exposed in network requests
  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyC9IPCNqLQQ7sCSijhzrzswvge6yE76XqE', // Publicly exposed in browser bundle
    projectId: 'dave-173321',
    messagingSenderId: '294403164518', // Cloud Messaging
    appId: '1:294403164518:web:83999023f4c181e2ab20a7',
  },
  API_URL: 'https://api.dave.com',
  APPSFLYER_INVITE_ONE_LINK_ID: '2wkx',
  BRAZE_KEY: '52814db4-e94a-443e-ae41-38eeb07c5773',
  BRAZE_ENDPOINT: 'sdk.iad-03.braze.com',
  PROMOTIONS_API_URL: 'https://promotions.trydave.com',
};

const stagingConfig = {
  ENVIRONMENT: ENVIRONMENTS.STAGING,
};

const devConfig = {
  ENVIRONMENT: ENVIRONMENTS.DEVELOPMENT,
  ENABLE_ANALYTICS: false,
};

export const updateConfig = (env: string, configOverride = {}): void => {
  if (env === ENVIRONMENTS.DEVELOPMENT) {
    Object.assign(Config, defaultConfig, devConfig, configOverride);
  } else if (env === ENVIRONMENTS.STAGING) {
    Object.assign(Config, defaultConfig, stagingConfig, configOverride);
  } else {
    Object.assign(Config, defaultConfig, prodConfig);
  }
};

updateConfig(ENVIRONMENT);

export const isNotProdEnv = (): boolean => {
  return Config.ENVIRONMENT.toLocaleLowerCase() !== ENVIRONMENTS.PRODUCTION;
};

export const isStagingEnv = (): boolean => {
  return Config.ENVIRONMENT.toLocaleLowerCase() === ENVIRONMENTS.STAGING;
};

export const isDevEnv = (): boolean => {
  return Config.ENVIRONMENT.toLocaleLowerCase() === ENVIRONMENTS.DEVELOPMENT;
};
