import * as Braze from '@braze/web-sdk';

import { EVENTS } from './analytics/constants/analytics-events';
import { Config, isDevEnv } from './config';
import { isBrowser } from './helpers';

const BRAZE_EVENTS = [EVENTS.LINK_BUTTON_CLICKED, EVENTS.FORM_FIELD_AUTOFILL];
let isInitialized = false;

export function initBraze() {
  if (!isInitialized && isBrowser) {
    Braze.initialize(Config.BRAZE_KEY, {
      enableLogging: isDevEnv(),
      baseUrl: Config.BRAZE_ENDPOINT,
      enableSdkAuthentication: true,
    });
    isInitialized = true;
  }
}

export function trackEvent(event: string, eventValues = {}) {
  if (BRAZE_EVENTS.indexOf(event) >= 0) {
    Braze.logCustomEvent(event, eventValues);
  }
}

export const setEmail = async (email: string | null) => {
  return Braze.getUser()?.setEmail(email);
};

export const setUserProperty = async (
  property: string,
  value: number | boolean | Date | string | string[] | object | object[] | null,
) => {
  return Braze.getUser()?.setCustomUserAttribute(property, value);
};
